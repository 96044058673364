import React, { useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { useNavigate } from "react-router-dom"; // Import useHistory
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported

const BookAppointmentModal = ({ show, setShow }) => {
  const { t } = useTranslation(); // Initialize translation function
  const navigate = useNavigate(); // Initialize history for navigation
  const [formData, setFormData] = useState({
    ownerName: "",
    ownerEmail: "",
    phoneNumber: "",
    petName: "",
    preferredDate: "",
    preferredTime: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://kwaconsultation-8120a29fdcab.herokuapp.com/api/appointments/submit", // Updated to Heroku API
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        alert(data.message); // Show success message
        setShow(false); // Close modal
        setFormData({
          // Reset form data
          ownerName: "",
          ownerEmail: "",
          phoneNumber: "",
          petName: "",
          preferredDate: "",
          preferredTime: "",
          message: "",
        });
        navigate("/"); // Redirect to home page
      } else {
        const errorData = await response.json();
        console.error(errorData.error); // Handle error
        alert("There was an error submitting your request."); // Inform user
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error submitting your request."); // Inform user
    }
  };

  if (!show) return null; // Don't render if not showing

  return (
    <div
      className="modal show fade"
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-lg">
        <div
          className="modal-content"
          style={{ borderRadius: "10px", backgroundColor: "#f9f9f9" }}
        >
          <div
            className="modal-header"
            style={{ backgroundColor: "#2c94d5", color: "#fff" }}
          >
            <h5 className="modal-title">{t("appointmentModal.title")}</h5>
            <button
              type="button"
              className="close"
              onClick={() => setShow(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "15px",
                backgroundColor: "transparent",
                border: "none",
                color: "#fff",
                fontSize: "1.5rem",
              }}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>{t("appointmentModal.ownerName")}</label>
                <input
                  type="text"
                  className="form-control"
                  name="ownerName"
                  placeholder={t("appointmentModal.ownerNamePlaceholder")}
                  value={formData.ownerName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>{t("appointmentModal.ownerEmail")}</label>
                <input
                  type="email"
                  className="form-control"
                  name="ownerEmail"
                  placeholder={t("appointmentModal.ownerEmailPlaceholder")}
                  value={formData.ownerEmail}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>{t("appointmentModal.phoneNumber")}</label>
                <input
                  type="tel"
                  className="form-control"
                  name="phoneNumber"
                  placeholder={t("appointmentModal.phoneNumberPlaceholder")}
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>{t("appointmentModal.petName")}</label>
                <input
                  type="text"
                  className="form-control"
                  name="petName"
                  placeholder={t("appointmentModal.petNamePlaceholder")}
                  value={formData.petName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>{t("appointmentModal.preferredDate")}</label>
                <input
                  type="date"
                  className="form-control"
                  name="preferredDate"
                  value={formData.preferredDate}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>{t("appointmentModal.preferredTime")}</label>
                <select
                  className="form-control"
                  name="preferredTime"
                  value={formData.preferredTime}
                  onChange={handleChange}
                >
                  <option value="">
                    —{t("appointmentModal.timeOptions.placeholder")}—
                  </option>
                  <option value="Morning (9:00 AM - 12:00 PM)">
                    {t("appointmentModal.timeOptions.morning")}
                  </option>
                  <option value="Afternoon (12:00 PM - 3:00 PM)">
                    {t("appointmentModal.timeOptions.afternoon")}
                  </option>
                  <option value="Evening (3:00 PM - 6:00 PM)">
                    {t("appointmentModal.timeOptions.evening")}
                  </option>
                </select>
              </div>
              <div className="form-group">
                <label>{t("appointmentModal.yourMessage")}</label>
                <textarea
                  className="form-control"
                  rows="3"
                  name="message"
                  placeholder={t("appointmentModal.messagePlaceholder")}
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <p style={{ color: "#999" }}>
                {t("appointmentModal.confirmationText")}
              </p>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShow(false)}
                >
                  {t("appointmentModal.close")}
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#90c349", borderColor: "#90c349" }}
                >
                  {t("appointmentModal.submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookAppointmentModal;
