import React from 'react';
import './VetConsult.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ParagraphImg from '../Images/ultrasound.jpg';
import LeftImg from '../Images/catportrait.jpg';
import RightImg from '../Images/jumpingdog.png';
import { faStethoscope, faHeartbeat, faEye } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function Ultrasound() {
    const { t } = useTranslation();

    return (
        <div className="ultrasound">
            <div className="header" style={{ backgroundColor: '#2c94d5', color: '#fff', padding: '2rem', textAlign: 'center' }}>
                <h1>{t('ultrasound.title')}</h1>
            </div>

            <div className="container consultations-intro-container d-flex justify-content-between align-items-center mt-5 mb-5">
                <section className="consultations-intro" style={{ flex: 1, paddingRight: '2rem' }}>
                    <h2 style={{ color: '#2c94d5' }}>{t('ultrasound.diagnosticImaging.header')}</h2>
                    <p>{t('ultrasound.diagnosticImaging.description')}</p>
                </section>
                <div className="consultations-intro-image" style={{ flex: 1 }}>
                    <img src={ParagraphImg} alt="Ultrasound service" style={{ width: '100%', maxWidth: '500px', borderRadius: '10px' }} />
                </div>
            </div>

            <section className="consultations-with-images mt-5 mb-5">
                <h2 style={{ color: '#2c94d5', textAlign: 'center' }}>{t('ultrasound.ultrasoundServices')}</h2>
                <div className="consultations-container d-flex justify-content-between align-items-center">
                    <div className="portrait-image">
                        <img src={LeftImg} alt="Left image" className="consultation-img" />
                    </div>
                    <div className="consultations-portrait d-flex flex-column align-items-center">
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faStethoscope} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('ultrasound.benefits.items.accurateDiagnosis')}</p>
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faHeartbeat} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('ultrasound.benefits.items.immediateInsights')}</p>
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faEye} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('ultrasound.benefits.items.nonInvasiveProcedure')}</p>
                        </div>
                    </div>
                    <div className="portrait-image">
                        <img src={RightImg} alt="Right image" className="consultation-img" />
                    </div>
                </div>
            </section>

            <section className="benefits mt-5 mb-5">
                <h2 style={{ color: '#90c349' }}>{t('ultrasound.benefits.header')}</h2>
                <div className="benefits-container d-flex justify-content-between">
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faStethoscope} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('ultrasound.benefits.items.accurateDiagnosis')}</p>
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faHeartbeat} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('ultrasound.benefits.items.immediateInsights')}</p>
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faEye} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('ultrasound.benefits.items.nonInvasiveProcedure')}</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Ultrasound;
