import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../Images/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import BookAppointmentModal from "./BookAppointmentModal";
import "./Header.css"; // Import the CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      {/* Top bar */}
      <div className="top-bar">
  <div className="container d-flex flex-column flex-md-row justify-content-between align-items-center">
    <div className="top-bar-item opening-hours">
      <i className="fas fa-clock mr-2"></i>
      {t("header.openingHours")}
    </div>
    <div className="top-bar-item contact-icons">
            {/* Icons for smaller screens */}
            <a href="https://wa.me/201030503022" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} className="mr-3" style={{ color: "#fff", fontSize: "20px" }} />
            </a>
            <a href="mailto:info@kalbwotta.clinic">
              <FontAwesomeIcon icon={faEnvelope} className="mr-3" style={{ color: "#fff", fontSize: "20px" }} />
            </a>
            <a href="tel:201030503022">
              <FontAwesomeIcon icon={faPhone} style={{ color: "#fff", fontSize: "20px" }} />
            </a>
          </div>
    <div className="top-bar-buttons d-flex flex-column flex-md-row">
      <button
        className="btn"
        style={{ backgroundColor: "#90c349", color: "#fff", marginBottom: "10px" }}
        onClick={toggleModal}
      >
        {t("header.requestAppointment")}
      </button>
      <div className="language-buttons">
        <button
          className="btn"
          style={{ backgroundColor: "#90c349", color: "#fff" }}
          onClick={() => changeLanguage("en")}
        >
          EN
        </button>
        <button
          className="btn"
          style={{ backgroundColor: "#90c349", color: "#fff" }}
          onClick={() => changeLanguage("ar")}
        >
          AR
        </button>
      </div>
    </div>
  </div>
</div>


      {/* Navbar */}
      <nav
        className="navbar navbar-expand-lg navbar-light"
        style={{ backgroundColor: "#fff", fontWeight: "500" }}
      >
        <a className="navbar-brand" href="#">
          <img
            src={logo}
            alt="Logo"
            style={{ width: "70px", height: "50px" }}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className="nav-link" href="/" style={{ color: "#2996ce" }}>
                {t("header.home")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="/about"
                style={{ color: "#2996ce" }}
              >
                {t("header.aboutUs")}
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#2996ce" }}
              >
                {t("header.services")}
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a className="dropdown-item" href="/vetconsult">
                  {t("header.vetConsultations")}
                </a>
                <a className="dropdown-item" href="/dental">
                  {t("header.dental")}
                </a>
                <a className="dropdown-item" href="/telemedicine">
                  {t("header.telemedicine")}
                </a>
                <a className="dropdown-item" href="/vaccination">
                  {t("header.vaccination")}
                </a>
                <a className="dropdown-item" href="/microchipping">
                  {t("header.microchipping")}
                </a>
                <a className="dropdown-item" href="/grooming">
                  {t("header.grooming")}
                </a>
                <a className="dropdown-item" href="/softtissuesurgery">
                  {t("header.softTissueSurgery")}
                </a>
                <a className="dropdown-item" href="/ultrasound">
                  {t("header.ultrasound")}
                </a>
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="/contact"
                style={{ color: "#2996ce" }}
              >
                {t("header.contact")}
              </a>
            </li>
          </ul>
        </div>
      </nav>

      {/* Book Appointment Modal */}
      {showModal && (
        <BookAppointmentModal show={showModal} setShow={setShowModal} />
      )}
    </div>
  );
};

export default Header;
