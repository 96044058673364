import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import img1 from "../Images/img1.jpg";
import img2 from "../Images/img2.jpg";
import img3 from "../Images/img3.jpg";
import img5 from "../Images/img5.jpg";
import img6 from "../Images/img6.jpg";
import img7 from "../Images/img7.jpg";
import petCareImage from "../Images/img4.jpg"; // Add the image for pet care
import { useTranslation } from "react-i18next";
import "./Homepage.css";

function HomePage() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contactData = {
      name: `${formData.firstName} ${formData.lastName}`, // Combine first and last name
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    try {
      const response = await fetch(
        "https://kwaconsultation-8120a29fdcab.herokuapp.com/api/contacts/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(contactData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);
      // You can reset the form or show a success message here
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="home-page">
      <Carousel style={{ maxHeight: 400, marginBottom: 20 }}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img1}
            alt={t("homepage.carouselAlt1")}
            style={{ maxHeight: 400, objectFit: "cover" }}
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img2}
            alt={t("homepage.carouselAlt2")}
            style={{ maxHeight: 400, objectFit: "cover" }}
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img3}
            alt={t("homepage.carouselAlt3")}
            style={{ maxHeight: 400, objectFit: "cover" }}
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div className="container" style={{ marginTop: 50 }}>
        <div className="row">
          <div className="col-md-6">
            <h1>
              {t("homepage.preventionKey")}{" "}
              <span className="blue-text">{t("homepage.key")}</span>
            </h1>
            <p className="larger-text">{t("homepage.petCareDescription")}</p>
          </div>
          <div className="col-md-6">
            <img
              src={petCareImage}
              alt={t("homepage.petCareAlt")}
              style={{ maxHeight: 500, width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div className="container" style={{ marginTop: 50 }}>
        <h1>
          {t("homepage.our")}{" "}
          <span className="blue-text">{t("homepage.services")}</span>
        </h1>
        <div className="row">
          <div className="col-md-4">
            <div className="service-container">
              <img
                src={img5}
                alt={t("homepage.service1Alt")}
                className="service-image"
              />
              <div className="service-info">
                <h3>{t("homepage.service1Title")}</h3>
                <p>{t("homepage.service1Description")}</p>
                <a href="/vetconsult">
                  <button>{t("homepage.learnMore")}</button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="service-container">
              <img
                src={img6}
                alt={t("homepage.service2Alt")}
                className="service-image"
              />
              <div className="service-info">
                <h3>{t("homepage.service2Title")}</h3>
                <p>{t("homepage.service2Description")}</p>
                <a href="/softtissuesurgery">
                  <button>{t("homepage.learnMore")}</button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="service-container">
              <img
                src={img7}
                alt={t("homepage.service3Alt")}
                className="service-image"
              />
              <div className="service-info">
                <h3>{t("homepage.service3Title")}</h3>
                <p>{t("homepage.service3Description")}</p>
                <a href="/grooming">
                  <button>{t("homepage.learnMore")}</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Get in Touch section */}
      <div
        className="container"
        style={{
          marginTop: 50,
          marginBottom: 50,
          backgroundColor: "#f0f0f0",
          borderRadius: 10,
          padding: 20,
        }}
      >
        <div className="row">
          <div className="col-md-6">
            <h1>
              {t("homepage.getIn")}{" "}
              <span className="blue-text">{t("homepage.touch")}</span>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p className="larger-text">{t("homepage.getInTouchDescription")}</p>
            <p>
              <FontAwesomeIcon icon={faPhone} /> {t("homepage.phone")}:
              01030503022-01030503023
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> {t("homepage.email")}:{" "}
              <a href="mailto:info@kalbwotta.clinic">info@kalbwotta.clinic</a>
            </p>
            <p>
              <FontAwesomeIcon icon={faMapMarker} /> {t("homepage.address")}:
              Elshorouk, middle road, Grand mall, Behind total petrol station,
              2nd floor, Clinic 11.
            </p>
            <p>
              <FontAwesomeIcon icon={faWhatsapp} /> {t("homepage.whatsapp")}:
              01030503022
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="firstName">{t("homepage.firstName")}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lastName">{t("homepage.lastName")}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="email">{t("homepage.email")}</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="phone">{t("homepage.phone")}</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="message">{t("homepage.message")}</label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="3"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                {t("homepage.sendMessage")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
