import React from 'react';
import './SoftTissue.css';
import SurgeryImg from '../Images/softtissuejpg.jpg';
import LeftImg from '../Images/catportrait.jpg';
import RightImg from '../Images/jumpingdog.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyringe, faHeartbeat, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function SoftTissueSurgery() {
    const { t } = useTranslation();

    return (
        <div className="soft-tissue-surgery">
            <div className="header" style={{ backgroundColor: '#2c94d5', color: '#fff', padding: '2rem', textAlign: 'center' }}>
                <h1>{t('softTissueSurgery.title')}</h1>
            </div>

            <div className="container consultations-intro-container d-flex justify-content-between align-items-center mt-5 mb-5">
                <section className="consultations-intro" style={{ flex: 1, paddingRight: '2rem' }}>
                    <h2 style={{ color: '#2c94d5' }}>{t('softTissueSurgery.whatToExpect.header')}</h2>
                    <p>{t('softTissueSurgery.whatToExpect.description')}</p>
                    <h3>{t('softTissueSurgery.whatToExpect.commonSurgeries')}</h3>
                    <ul>
                        {t('softTissueSurgery.whatToExpect.surgeries', { returnObjects: true }).map((surgery, index) => (
                            <li key={index}>{surgery}</li>
                        ))}
                    </ul>
                </section>
                <div className="consultations-intro-image" style={{ flex: 1 }}>
                    <img src={SurgeryImg} alt="Surgery service" style={{ width: '100%', maxWidth: '500px', borderRadius: '10px' }} />
                </div>
            </div>

            <section className="consultations-with-images mt-5 mb-5">
                <h2 style={{ color: '#2c94d5', textAlign: 'center' }}>{t('softTissueSurgery.surgeryServices')}</h2>
                <div className="consultations-container d-flex justify-content-between align-items-center">
                    <div className="portrait-image">
                        <img src={LeftImg} alt="Left image" className="consultation-img" />
                    </div>
                    <div className="consultations-portrait d-flex flex-column align-items-center">
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faSyringe} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('softTissueSurgery.whatToExpect.surgeries[0]')}</p>
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faHeartbeat} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('softTissueSurgery.whatToExpect.surgeries[5]')}</p>
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faPlusSquare} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('softTissueSurgery.whatToExpect.surgeries[2]')}</p>
                        </div>
                    </div>
                    <div className="portrait-image">
                        <img src={RightImg} alt="Right image" className="consultation-img" />
                    </div>
                </div>
            </section>

            <section className="benefits mt-5 mb-5">
                <h2 style={{ color: '#90c349' }}>{t('softTissueSurgery.benefits.header')}</h2>
                <div className="benefits-container d-flex justify-content-between">
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faSyringe} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('softTissueSurgery.benefits.items.advancedTechniques')}</p>
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faHeartbeat} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('softTissueSurgery.benefits.items.safeProcedures')}</p>
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faPlusSquare} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('softTissueSurgery.benefits.items.postOperativeCare')}</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SoftTissueSurgery;
