// Footer.js
import React from 'react';
import logo from '../Images/logoFooter.png';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const Footer = () => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <footer style={{ backgroundColor: '#2c94d5', padding: '1rem', marginTop: 'auto' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <img src={logo} alt="Logo" style={{ width: '100px', height: '100px' }} />
            <p style={{ color: '#fff', fontSize: '14px' }}>
              {t('footer.description')}
            </p>
          </div>
          <div className="col-md-4 offset-md-1">
            <div className="row">
              <div className="col-md-6">
                <h5 style={{ color: '#fff' }}>{t('footer.our_services')}</h5>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  <li><a href="/telemedicine" style={{ color: '#fff', textDecoration: 'none' }}>{t('footer.services.telemedicine')}</a></li>
                  <li><a href="/vetconsult" style={{ color: '#fff', textDecoration: 'none' }}>{t('footer.services.veterinary_consultation')}</a></li>
                  <li><a href="/dental" style={{ color: '#fff', textDecoration: 'none' }}>{t('footer.services.dental')}</a></li>
                  <li><a href="/vaccination" style={{ color: '#fff', textDecoration: 'none' }}>{t('footer.services.vaccination')}</a></li>
                  <li><a href="/microchipping" style={{ color: '#fff', textDecoration: 'none' }}>{t('footer.services.microchipping')}</a></li>
                  <li><a href="/grooming" style={{ color: '#fff', textDecoration: 'none' }}>{t('footer.services.grooming')}</a></li>
                  <li><a href="/softtissuesurgery" style={{ color: '#fff', textDecoration: 'none' }}>{t('footer.services.soft_tissue_surgery')}</a></li>
                  <li><a href="/ultrasound" style={{ color: '#fff', textDecoration: 'none' }}>{t('footer.services.ultrasound')}</a></li>
                </ul>
              </div>
              <div className="col-md-6">
                <h5 style={{ color: '#fff' }}>{t('footer.get_to_know_us')}</h5>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  <li><a href="/about" style={{ color: '#fff', textDecoration: 'none' }}>{t('footer.about_us')}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ borderTop: '1px solid #fff' }} />
        <span style={{ color: '#fff' }}>&copy; 2024 KalbwOtta</span>
      </div>
    </footer>
  );
};

export default Footer;
