import React from 'react';
import './Dental.css'; // Add your styles here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DentalImg from '../Images/dentalimage2.avif'; // Replace with your dental service image
import LeftImg from '../Images/catportrait.jpg'; // Image on the left
import RightImg from '../Images/jumpingdog.png'; // Image on the right
import { faTooth, faSyringe, faSmile } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next'; // Import useTranslation

function Dental() {
    const { t } = useTranslation(); // Initialize translation

    return (
        <div className="dental-service">
            {/* Header Section */}
            <div className="header" style={{ backgroundColor: '#2c94d5', color: '#fff', padding: '2rem', textAlign: 'center' }}>
                <h1>{t('dental.title')}</h1> {/* Translate title */}
            </div>

            {/* What to Expect Section */}
            <div className="container consultations-intro-container d-flex justify-content-between align-items-center mt-5 mb-5">
                <section className="consultations-intro" style={{ flex: 1, paddingRight: '2rem' }}>
                    <h2 style={{ color: '#2c94d5' }}>{t('dental.expect')}</h2> {/* Translate subtitle */}
                    <p>
                        {t('dental.intro')} {/* Translate introduction text */}
                    </p>
                </section>
                <div className="consultations-intro-image" style={{ flex: 1 }}>
                    <img src={DentalImg} alt="Dental service" style={{ width: '100%', maxWidth: '500px', borderRadius: '10px' }} />
                </div>
            </div>

            {/* Dental Services Section with Images on Left and Right */}
            <section className="consultations-with-images mt-5 mb-5">
                <h2 style={{ color: '#2c94d5', textAlign: 'center' }}>{t('dental.servicesTitle')}</h2> {/* Translate services title */}
                <div className="consultations-container d-flex justify-content-between align-items-center">
                    <div className="portrait-image">
                        <img src={LeftImg} alt="Left image" className="consultation-img" />
                    </div>
                    <div className="consultations-portrait d-flex flex-column align-items-center">
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faTooth} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('dental.teethCleaning')}</p> {/* Translate teeth cleaning text */}
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faSyringe} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('dental.toothExtractions')}</p> {/* Translate tooth extractions text */}
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faSmile} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('dental.minorOralSurgery')}</p> {/* Translate minor oral surgery text */}
                        </div>
                    </div>
                    <div className="portrait-image">
                        <img src={RightImg} alt="Right image" className="consultation-img" />
                    </div>
                </div>
            </section>

            {/* Benefits Section */}
            <section className="benefits mt-5 mb-5">
                <h2 style={{ color: '#90c349' }}>{t('dental.benefitsTitle')}</h2> {/* Translate benefits title */}
                <div className="benefits-container d-flex justify-content-between">
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faTooth} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('dental.benefit1')}</p> {/* Translate benefit 1 */}
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faSmile} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('dental.benefit2')}</p> {/* Translate benefit 2 */}
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faSyringe} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('dental.benefit3')}</p> {/* Translate benefit 3 */}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Dental;
