import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import './Telemedicine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faPhone, faComments, faFileAlt, faUser, faDog } from '@fortawesome/free-solid-svg-icons';
import TopImg from '../Images/telemedicne.webp';
import LeftImg from '../Images/catportrait.jpg';
import RightImg from '../Images/jumpingdog.png';

function Telemedicine() {
    const { t } = useTranslation(); // Initialize useTranslation
    const [formData, setFormData] = useState({
        ownerName: '',
        ownerEmail: '',
        phoneNumber: '',
        petName: '',
        petAge: '',
        petSpecies: '',
        petBreed: '',
        condition: '',
        fileUpload: null,
        additionalMessage: '',
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            fileUpload: e.target.files,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();

        for (const key in formData) {
            if (key === 'fileUpload' && formData.fileUpload) {
                for (const file of formData.fileUpload) {
                    formDataToSend.append(key, file);
                }
            } else {
                formDataToSend.append(key, formData[key]);
            }
        }

        try {
            const response = await fetch("https://kwaconsultation-8120a29fdcab.herokuapp.com/api/telemedicine/submit", {
                method: 'POST',
                body: formDataToSend,
            });

            if (response.ok) {
                setSuccessMessage(t('telemedicine.successMessage')); // Use translation
                setErrorMessage('');
                setFormData({
                    ownerName: '',
                    ownerEmail: '',
                    phoneNumber: '',
                    petName: '',
                    petAge: '',
                    petSpecies: '',
                    petBreed: '',
                    condition: '',
                    fileUpload: null,
                    additionalMessage: '',
                });
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.error || t('telemedicine.errorMessage')); // Use translation
                setSuccessMessage('');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage(t('telemedicine.errorMessage')); // Use translation
            setSuccessMessage('');
        }
    };

    return (
        <div className="telemedicine">
            <div className="header" style={{ backgroundColor: '#2c94d5', color: '#fff', padding: '2rem', textAlign: 'center' }}>
                <h1>{t('telemedicine.title')}</h1> {/* Use translation */}
            </div>

            <div className="container consultations-intro-container d-flex justify-content-between align-items-center mt-5 mb-5">
                <section className="consultations-intro" style={{ flex: 1, paddingRight: '2rem' }}>
                    <h2 style={{ color: '#2c94d5' }}>{t('telemedicine.virtualConsultations')}</h2> {/* Use translation */}
                    <p>
                        {t('telemedicine.introText')}
                    </p>
                </section>
                <div className="consultations-intro-image" style={{ flex: 1 }}>
                    <img src={TopImg} alt={t('telemedicine.altText')} style={{ width: '100%', maxWidth: '500px', borderRadius: '10px' }} />
                </div>
            </div>

            <section className="consultations-with-images mt-5 mb-5">
                <h2 style={{ color: '#2c94d5', textAlign: 'center' }}>{t('telemedicine.howItWorks')}</h2> {/* Use translation */}
                <div className="consultations-container d-flex justify-content-between align-items-center">
                    <div className="portrait-image">
                        <img src={LeftImg} alt={t('telemedicine.videoCallAlt')} className="consultation-img" />
                    </div>
                    <div className="consultations-portrait d-flex flex-column align-items-center">
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faVideo} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('telemedicine.videoConsultations')}</p> {/* Use translation */}
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faPhone} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('telemedicine.phoneConsultations')}</p> {/* Use translation */}
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faComments} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('telemedicine.followUpAdvice')}</p> {/* Use translation */}
                        </div>
                    </div>
                    <div className="portrait-image">
                        <img src={RightImg} alt={t('telemedicine.petCareAlt')} className="consultation-img" />
                    </div>
                </div>
            </section>

            <section className="benefits mt-5 mb-5">
                <h2 style={{ color: '#90c349', textAlign: 'center' }}>{t('telemedicine.benefitsTitle')}</h2> {/* Use translation */}
                <div className="benefits-container d-flex justify-content-between">
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faUser} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('telemedicine.benefitConvenient')}</p> {/* Use translation */}
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faDog} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('telemedicine.benefitBehavioral')}</p> {/* Use translation */}
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faFileAlt} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('telemedicine.benefitRemote')}</p> {/* Use translation */}
                    </div>
                </div>
            </section>

            <div className="form-section mt-5 mb-5">
                <h2 style={{ color: '#2c94d5', textAlign: 'center' }}>{t('telemedicine.formTitle')}</h2> {/* Use translation */}
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                <form className="telemedicine-form" onSubmit={handleSubmit}>
                    <div className="form-row">
                        <label htmlFor="ownerName">{t('telemedicine.fullName')}*</label> {/* Use translation */}
                        <input type="text" id="ownerName" name="ownerName" required value={formData.ownerName} onChange={handleChange} />
                    </div>
                    <div className="form-row">
                        <label htmlFor="ownerEmail">{t('telemedicine.email')}*</label> {/* Use translation */}
                        <input type="email" id="ownerEmail" name="ownerEmail" required value={formData.ownerEmail} onChange={handleChange} />
                    </div>
                    <div className="form-row">
                        <label htmlFor="phoneNumber">{t('telemedicine.phoneNumber')}*</label> {/* Use translation */}
                        <input type="tel" id="phoneNumber" name="phoneNumber" required value={formData.phoneNumber} onChange={handleChange} />
                    </div>
                    <div className="form-row">
                        <label htmlFor="petName">{t('telemedicine.petName')}*</label> {/* Use translation */}
                        <input type="text" id="petName" name="petName" required value={formData.petName} onChange={handleChange} />
                    </div>
                    <div className="form-row">
                        <label htmlFor="petAge">{t('telemedicine.petAge')}*</label> {/* Use translation */}
                        <input type="number" id="petAge" name="petAge" required value={formData.petAge} onChange={handleChange} />
                    </div>
                    <div className="form-row">
                        <label htmlFor="petSpecies">{t('telemedicine.petSpecies')}*</label> {/* Use translation */}
                        <input type="text" id="petSpecies" name="petSpecies" required value={formData.petSpecies} onChange={handleChange} />
                    </div>
                    <div className="form-row">
                        <label htmlFor="petBreed">{t('telemedicine.petBreed')}*</label> {/* Use translation */}
                        <input type="text" id="petBreed" name="petBreed" required value={formData.petBreed} onChange={handleChange} />
                    </div>
                    <div className="form-row">
                        <label htmlFor="condition">{t('telemedicine.condition')}*</label> {/* Use translation */}
                        <textarea id="condition" name="condition" required value={formData.condition} onChange={handleChange}></textarea>
                    </div>
                    <div className="form-row">
                        <label htmlFor="fileUpload">{t('telemedicine.uploadFile')}</label> {/* Use translation */}
                        <input type="file" id="fileUpload" name="fileUpload" onChange={handleFileChange} />
                    </div>
                    <div className="form-row">
                        <label htmlFor="additionalMessage">{t('telemedicine.additionalMessage')}</label> {/* Use translation */}
                        <textarea id="additionalMessage" name="additionalMessage" value={formData.additionalMessage} onChange={handleChange}></textarea>
                    </div>
                    <button type="submit">{t('telemedicine.submit')}</button> {/* Use translation */}
                </form>
            </div>
        </div>
    );
}

export default Telemedicine;
