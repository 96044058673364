import React from 'react';
import backgroundImage from '../Images/img8.jpg'; // Make sure this path is correct
import logoImage from '../Images/logo.png'; // Add your logo path
import './AboutUs.css';
import { useTranslation } from 'react-i18next'; // Import useTranslation

function AboutUs({ setShowModal }) {  // Accept the prop
  const { t } = useTranslation(); // Initialize useTranslation

  return (
    <div className="about-us">
      {/* Section for the background image and title */}
      <div className="about-us-header">
        <h1>{t('aboutUs.title')}</h1>
      </div>

      {/* Section for the text and logo */}
      <div className="about-us-content">
        <div className="text-section">
          <h2>{t('aboutUs.storyTitle')}</h2>
          <p>{t('aboutUs.storyText')}</p>
        </div>
        <div className="logo-section">
          <img src={logoImage} alt="Company Logo" />
        </div>
      </div>

      {/* Section for Why Choose Us */}
      <div className="why-choose-us">
        <h2>{t('aboutUs.whyChooseUsTitle')}</h2>
        <div className="reasons-list">
          {t('aboutUs.reasons', { returnObjects: true }).map((reason, index) => (
            <div className="reason-card" key={index}>
              <div className="reason-icon">{reason.icon}</div>
              <h3>{reason.title}</h3>
              <p>{reason.text}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Mission Statement Section */}
      <div className="mission-statement">
        <h2>{t('aboutUs.missionTitle')}</h2>
        <p>{t('aboutUs.missionText')}</p>
      </div>

      {/* Testimonials Section */}
      <div className="testimonials-section">
        <h2>{t('aboutUs.testimonialsTitle')}</h2>
        <div className="testimonials-list">
          {t('aboutUs.testimonials', { returnObjects: true }).map((testimonial, index) => (
            <div className="testimonial" key={index}>
              <p>{testimonial.quote}</p>
              <h4>{testimonial.author}</h4>
            </div>
          ))}
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="call-to-action">
        <h2>{t('aboutUs.callToActionTitle')}</h2>
        <p>{t('aboutUs.callToActionText')}</p>
        <button className="cta-button" onClick={() => setShowModal(true)}>
          {t('aboutUs.ctaButton')}
        </button>
      </div>
    </div>
  );
}

export default AboutUs;
