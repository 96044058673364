import React from 'react';
import './VetConsult.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VaccinationImg from '../Images/vaccinations.jpg';
import LeftImg from '../Images/catportrait.jpg';
import RightImg from '../Images/jumpingdog.png';
import { faSyringe, faShieldAlt, faVirus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function Vaccinations() {
    const { t } = useTranslation();

    return (
        <div className="vaccinations">
            <div className="header" style={{ backgroundColor: '#2c94d5', color: '#fff', padding: '2rem', textAlign: 'center' }}>
                <h1>{t('vaccinations.title')}</h1>
            </div>

            <div className="container consultations-intro-container d-flex justify-content-between align-items-center mt-5 mb-5">
                <section className="consultations-intro" style={{ flex: 1, paddingRight: '2rem' }}>
                    <h2 style={{ color: '#2c94d5' }}>{t('vaccinations.whatToExpect.header')}</h2>
                    <p>{t('vaccinations.whatToExpect.description')}</p>
                </section>
                <div className="consultations-intro-image" style={{ flex: 1 }}>
                    <img src={VaccinationImg} alt="Vaccination service" style={{ width: '100%', maxWidth: '500px', borderRadius: '10px' }} />
                </div>
            </div>

            <section className="consultations-with-images mt-5 mb-5">
                <h2 style={{ color: '#2c94d5', textAlign: 'center' }}>{t('vaccinations.ourVaccinationServices')}</h2>
                <div className="consultations-container d-flex justify-content-between align-items-center">
                    <div className="portrait-image">
                        <img src={LeftImg} alt="Left image" className="consultation-img" />
                    </div>
                    <div className="consultations-portrait d-flex flex-column align-items-center">
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faSyringe} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('vaccinations.services.comprehensivePrograms')}</p>
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faShieldAlt} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('vaccinations.services.preventiveHealthMeasures')}</p>
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faVirus} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('vaccinations.services.protectionAgainstDiseases')}</p>
                        </div>
                    </div>
                    <div className="portrait-image">
                        <img src={RightImg} alt="Right image" className="consultation-img" />
                    </div>
                </div>
            </section>

            <section className="benefits mt-5 mb-5">
                <h2 style={{ color: '#90c349' }}>{t('vaccinations.benefits.header')}</h2>
                <div className="benefits-container d-flex justify-content-between">
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faSyringe} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('vaccinations.benefits.items.boostingImmunity')}</p>
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faShieldAlt} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('vaccinations.benefits.items.preventingSeriousIllnesses')}</p>
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faVirus} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('vaccinations.benefits.items.safeguardingHealth')}</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Vaccinations;
