import React from 'react';
import './VetConsult.css'; // Add your styles here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpectImg from '../Images/dog-cat-kisses.jpg'; // Example image
import LeftImg from '../Images/catportrait.jpg'; // New left image
import RightImg from '../Images/jumpingdog.png'; // New right image
import ParagraphImg from '../Images/image-43642-800.jpg'; // Image for the new paragraph section
import { faShieldDog, faStethoscope, faSyringe, faBrain, faHeart } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next'; // Import useTranslation

function VetConsult() {
    const { t } = useTranslation(); // Initialize translation

    return (
        <div className="veterinary-consultations">
            {/* Header Section */}
            <div className="header" style={{ backgroundColor: '#2c94d5', color: '#fff', padding: '2rem', textAlign: 'center' }}>
                <h1>{t('vetConsult.title')}</h1> {/* Translate title */}
            </div>

            {/* New Veterinary Consultations Intro Section */}
            <div className="container consultations-intro-container d-flex justify-content-between align-items-center mt-5 mb-5">
                <section className="consultations-intro" style={{ flex: 1, paddingRight: '2rem' }}>
                    <h2 style={{ color: '#2c94d5' }}>{t('vetConsult.expect')}</h2> {/* Translate subtitle */}
                    <p>
                        {t('vetConsult.intro')} {/* Translate introduction text */}
                    </p>
                </section>
                <div className="consultations-intro-image" style={{ flex: 1 }}>
                    <img src={ParagraphImg} alt="Vet consultation" style={{ width: '100%', maxWidth: '500px', borderRadius: '10px' }} />
                </div>
            </div>

            {/* Veterinary Consultations Section with Images on Left and Right */}
            <section className="consultations-with-images mt-5 mb-5">
                <h2 style={{ color: '#2c94d5', textAlign: 'center' }}>{t('vetConsult.servicesTitle')}</h2> {/* Translate services title */}
                <div className="consultations-container d-flex justify-content-between align-items-center">
                    <div className="portrait-image">
                        <img src={LeftImg} alt="Left image" className="consultation-img" />
                    </div>
                    <div className="consultations-portrait d-flex flex-column align-items-center">
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faStethoscope} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('vetConsult.dermatology')}</p> {/* Translate dermatology text */}
                        </div>
                        
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faHeart} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('vetConsult.cardiology')}</p> {/* Translate cardiology text */}
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faSyringe} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('vetConsult.vaccinations')}</p> {/* Translate vaccinations text */}
                        </div>
                    </div>
                    <div className="portrait-image">
                        <img src={RightImg} alt="Right image" className="consultation-img" />
                    </div>
                </div>
            </section>

            {/* Benefits Section */}
            <section className="benefits mt-5 mb-5">
                <h2 style={{ color: '#90c349' }}>{t('vetConsult.benefitsTitle')}</h2> {/* Translate benefits title */}
                <div className="benefits-container d-flex justify-content-between">
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faShieldDog} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('vetConsult.benefit1')}</p> {/* Translate benefit 1 */}
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faStethoscope} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('vetConsult.benefit2')}</p> {/* Translate benefit 2 */}
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faSyringe} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('vetConsult.benefit3')}</p> {/* Translate benefit 3 */}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default VetConsult;
