import React, { useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation for language config
import "./ContactUs.css"; // CSS for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocationImg from "../Images/location.png";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function ContactUs() {
  const { t } = useTranslation(); // Initialize translation function
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const contactData = {
      name: `${formData.firstName} ${formData.lastName}`, // Combine first and last name
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    try {
      const response = await fetch(
        "https://kwaconsultation-8120a29fdcab.herokuapp.com/api/contact/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(contactData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(data.message); // Set success message
        // Reset the form
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        const errorData = await response.json();
        console.error(errorData.error); // Handle error
        setSuccessMessage(""); // Clear success message on error
      }
    } catch (error) {
      console.error("Error:", error);
      setSuccessMessage(""); // Clear success message on error
    }
  };

  return (
    <div className="contact-us">
      {/* Header Section */}
      <div
        className="header"
        style={{
          backgroundColor: "#2c94d5",
          color: "#fff",
          padding: "2rem",
          textAlign: "center",
        }}
      >
        <h1>{t("contactUs.title")}</h1> {/* Translated title */}
      </div>

      {/* Location and Working Hours Section */}
      <div className="container mt-5 mb-5 d-flex justify-content-between">
        <div className="map-container" style={{ flex: 1 }}>
          <h2 style={{ color: "#2c94d5" }}>{t("contactUs.locationTitle")}</h2>{" "}
          {/* Translated location title */}
          <img
            src={LocationImg} // Replace with the actual image URL
            alt="Clinic Location"
            width="100%"
            height="300"
            style={{ border: "0", borderRadius: "10px" }}
          />
        </div>
        <div className="working-hours" style={{ flex: 1, paddingLeft: "2rem" }}>
          <h2 style={{ color: "#2c94d5" }}>
            {t("contactUs.workingHoursTitle")}
          </h2>{" "}
          {/* Translated working hours title */}
          <p>{t("contactUs.workingHours")}</p> {/* Translated working hours */}
          <p>{t("contactUs.emergencyContact")}</p>{" "}
          {/* Translated emergency contact */}
          <a
            href="https://www.google.com/maps/place/Kalb+w+Otta/@30.1501379,31.6255625,17z/data=!3m1!4b1!4m6!3m5!1s0x14581dcaffcfd577:0xe987d0955eb3065c!8m2!3d30.1501379!4d31.6281374!16s%2Fg%2F11qm3qq5r4?entry=ttu&g_ep=EgoyMDI0MDkxNi4wIKXMDSoASAFQAw%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              style={{
                marginTop: "1rem",
                backgroundColor: "#90c349",
                color: "#fff",
                padding: "1rem 2rem",
                borderRadius: "5px",
                borderColor: "transparent",
              }}
            >
              {t("contactUs.getDirections")} {/* Translated button text */}
            </button>
          </a>
        </div>
      </div>

      {/* Contact Icons Section */}
      <div className="contact-icons d-flex justify-content-around mb-5">
        <a href="tel:+201030503022">
          <FontAwesomeIcon
            icon={faPhone}
            size="3x"
            style={{ color: "#2c94d5" }}
          />
        </a>
        <a
          href="https://wa.me/201030503022"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faWhatsapp}
            size="3x"
            style={{ color: "#2c94d5" }}
          />
        </a>
        <a href="mailto:info@kalbwotta.clinic">
          <FontAwesomeIcon
            icon={faEnvelope}
            size="3x"
            style={{ color: "#2c94d5" }}
          />
        </a>
      </div>

      {/* Get in Touch Form */}
      <div
        className="container"
        style={{
          marginTop: 50,
          marginBottom: 50,
          backgroundColor: "#f0f0f0",
          borderRadius: 10,
          padding: 20,
        }}
      >
        <div className="row">
          <div className="col-md-6">
            <h1>
              {t("contactUs.getInTouch")} {/* Translated get in touch title */}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p className="larger-text">
              {t("contactUs.questions")} {/* Translated questions text */}
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} /> {t("contactUs.phone")}:
              01030503022-01030503023
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> {t("contactUs.email")}:{" "}
              <a href="mailto:info@kalbwotta.clinic">info@kalbwotta.clinic</a>
            </p>
            <p>
              <FontAwesomeIcon icon={faMapMarker} /> {t("contactUs.address")}:{" "}
              {t("contactUs.addressPlaceholder")}
            </p>
            <p>
              <FontAwesomeIcon icon={faWhatsapp} /> {t("contactUs.whatsapp")}:
              01030503022
            </p>
          </div>
          <div className="col-md-6">{/* empty div to maintain layout */}</div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="firstName">
                      {t("contactUs.firstName")}
                    </label>{" "}
                    {/* Translated first name label */}
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lastName">{t("contactUs.lastName")}</label>{" "}
                    {/* Translated last name label */}
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="email">{t("contactUs.email")}</label>{" "}
                    {/* Translated email label */}
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="phone">{t("contactUs.phoneLabel")}</label>{" "}
                    {/* Translated phone label */}
                    <input
                      type="tel"
                      className="form-control"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="message">{t("contactUs.message")}</label>{" "}
                {/* Translated message label */}
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="3"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                {t("contactUs.send")} {/* Translated send button */}
              </button>
              {successMessage && (
                <div className="alert alert-success mt-3">{successMessage}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
