import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AdminDashBoard.css";

const AdminDashboard = () => {
  const [appointments, setAppointments] = useState([]);
  const [telemedicineRequests, setTelemedicineRequests] = useState([]);
  const [contactSubmissions, setContactSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch appointments, telemedicine, and contact data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [appointmentsRes, telemedicineRes, contactRes] =
          await Promise.all([
            axios.get(
              "https://kwaconsultation-8120a29fdcab.herokuapp.com/api/appointments"
            ),
            axios.get(
              "https://kwaconsultation-8120a29fdcab.herokuapp.com/api/telemedicine"
            ),
            axios.get(
              "https://kwaconsultation-8120a29fdcab.herokuapp.com/api/contacts"
            ),
          ]);

        setAppointments(appointmentsRes.data);  
        setTelemedicineRequests(telemedicineRes.data);
        setContactSubmissions(contactRes.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>

      {/* Appointments Section */}
      <section>
        <h2>Appointments</h2>
        {appointments.length > 0 ? (
          <ul>
            {appointments.map((appointment) => (
              <li key={appointment._id}>
                <strong>Owner:</strong> {appointment.ownerName} <br />
                <strong>Email:</strong> {appointment.ownerEmail} <br />
                <strong>Phone:</strong> {appointment.phoneNumber} <br />
                <strong>Pet Name:</strong> {appointment.petName} <br />
                <strong>Date:</strong>{" "}
                {new Date(appointment.preferredDate).toLocaleDateString()}{" "}
                <br />
                <strong>Time:</strong> {appointment.preferredTime} <br />
                <strong>Message:</strong> {appointment.message} <br />
                <hr />
              </li>
            ))}
          </ul>
        ) : (
          <p>No appointments found</p>
        )}
      </section>





      {/* Telemedicine Requests Section */}
      <section>
        <h2>Telemedicine Requests</h2>
        {telemedicineRequests.length > 0 ? (
          <ul>
            {telemedicineRequests.map((request) => (
              <li key={request._id}>
                <strong>Owner:</strong> {request.ownerName} <br />
                <strong>Email:</strong> {request.ownerEmail} <br />
                <strong>Pet Name:</strong> {request.petName} <br />
                <strong>Age:</strong> {request.petAge} <br />
                <strong>Breed:</strong> {request.petBreed} <br />
                <strong>Condition:</strong> {request.condition} <br />
                <strong>Message:</strong> {request.additionalMessage} <br />
                <strong>Submitted Files:</strong>
                <ul>
                  {request.fileUpload.map((file, index) => (
                    <li key={index}>
                      <a href={file} target="_blank" rel="noopener noreferrer">
                        View File {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
                <hr />
              </li>
            ))}
          </ul>
        ) : (
          <p>No telemedicine requests found.</p>
        )}
      </section>

      {/* Contact Submissions Section */}
      <section>
        <h2>Contact Submissions</h2>
        {contactSubmissions.length > 0 ? (
          <ul>
            {contactSubmissions.map((submission) => (
              <li key={submission._id}>
                <strong>Name:</strong> {submission.name} <br />
                <strong>Email:</strong> {submission.email} <br />
                <strong>Message:</strong> {submission.message} <br />
                {submission.fileUpload && (
                  <>
                    <strong>File Uploaded:</strong>
                    <a
                      href={submission.fileUpload}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View File
                    </a>
                  </>
                )}
                <hr />
              </li>
            ))}
          </ul>
        ) : (
          <p>No contact submissions found.</p>
        )}
      </section>
    </div>
  );
};

export default AdminDashboard;
