import React from 'react';
import './VetConsult.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GroomingImg from '../Images/grooming.jpg';
import LeftImg from '../Images/catportrait.jpg';
import RightImg from '../Images/jumpingdog.png';
import { faScissors, faShower, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function Grooming() {
    const { t } = useTranslation();

    return (
        <div className="grooming">
            <div className="header" style={{ backgroundColor: '#2c94d5', color: '#fff', padding: '2rem', textAlign: 'center' }}>
                <h1>{t('grooming.title')}</h1>
            </div>

            <div className="container consultations-intro-container d-flex justify-content-between align-items-center mt-5 mb-5">
                <section className="consultations-intro" style={{ flex: 1, paddingRight: '2rem' }}>
                    <h2 style={{ color: '#2c94d5' }}>{t('grooming.whatToExpect.header')}</h2>
                    <p>{t('grooming.whatToExpect.description')}</p>
                </section>
                <div className="consultations-intro-image" style={{ flex: 1 }}>
                    <img src={GroomingImg} alt="Grooming service" style={{ width: '100%', maxWidth: '500px', borderRadius: '10px' }} />
                </div>
            </div>

            <section className="consultations-with-images mt-5 mb-5">
                <h2 style={{ color: '#2c94d5', textAlign: 'center' }}>{t('grooming.groomingServices')}</h2>
                <div className="consultations-container d-flex justify-content-between align-items-center">
                    <div className="portrait-image">
                        <img src={LeftImg} alt="Left image" className="consultation-img" />
                    </div>
                    <div className="consultations-portrait d-flex flex-column align-items-center">
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faScissors} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('grooming.services.coatTrimming')}</p>
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faShower} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('grooming.services.bathing')}</p>
                        </div>
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faCheckCircle} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('grooming.services.healthCheck')}</p>
                        </div>
                    </div>
                    <div className="portrait-image">
                        <img src={RightImg} alt="Right image" className="consultation-img" />
                    </div>
                </div>
            </section>

            <section className="benefits mt-5 mb-5">
                <h2 style={{ color: '#90c349' }}>{t('grooming.benefits.header')}</h2>
                <div className="benefits-container d-flex justify-content-between">
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faScissors} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('grooming.benefits.items.properMaintenance')}</p>
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faShower} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('grooming.benefits.items.improvedHygiene')}</p>
                    </div>
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faCheckCircle} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('grooming.benefits.items.earlyDetection')}</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Grooming;
