import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import HomePage from './pages/Homepage.js';
import AboutUs from './pages/AboutUs.js';
import VetConsult from './pages/VetConsult.js';
import Dental from './pages/Dental.js';
import Vaccination from './pages/Vaccinations.js';
import Micropchipping from './pages/Microchipping.js';
import Grooming from './pages/Grooming.js';
import SoftTissueSurgery from './pages/SoftTissue.js';
import Ultrasound from './pages/Ultrasound.js';
import Telemedicine from './pages/Telemedicine.js';
import ContactUs from './pages/ContactUs.js';
import BookAppointmentModal from './components/BookAppointmentModal.js'; // Import the modal
import AdminDashboard from './pages/AdminDashBoard.js';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n.js'; // Import your i18n configuration

function App() {
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <div className="App">
          <Header setShowModal={setShowModal} />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutUs setShowModal={setShowModal} />} />
            <Route path="/vetconsult" element={<VetConsult />} />
            <Route path="/dental" element={<Dental />} />
            <Route path="/vaccination" element={<Vaccination />} />
            <Route path="/microchipping" element={<Micropchipping />} />
            <Route path="/grooming" element={<Grooming />} />
            <Route path="/softtissuesurgery" element={<SoftTissueSurgery />} />
            <Route path="/ultrasound" element={<Ultrasound />} />
            <Route path="/telemedicine" element={<Telemedicine />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/admin" element={<AdminDashboard />} />
          </Routes>
          <Footer />
          <BookAppointmentModal show={showModal} setShow={setShowModal} /> {/* Render the modal */}
        </div>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
