import React from 'react';
import './VetConsult.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MicrochipImg from '../Images/microchip.jpg';
import LeftImg from '../Images/catportrait.jpg';
import RightImg from '../Images/jumpingdog.png';
import { faMicrochip, faSearch, faLock } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function Microchipping() {
    const { t } = useTranslation();

    return (
        <div className="microchipping">
            <div className="header" style={{ backgroundColor: '#2c94d5', color: '#fff', padding: '2rem', textAlign: 'center' }}>
                <h1>{t('microchipping.title')}</h1>
            </div>

            <div className="container consultations-intro-container d-flex justify-content-between align-items-center mt-5 mb-5">
                <section className="consultations-intro" style={{ flex: 1, paddingRight: '2rem' }}>
                    <h2 style={{ color: '#2c94d5' }}>{t('microchipping.whatToExpect.header')}</h2>
                    <p>{t('microchipping.whatToExpect.description')}</p>
                </section>
                <div className="consultations-intro-image" style={{ flex: 1 }}>
                    <img src={MicrochipImg} alt="Microchipping service" style={{ width: '100%', maxWidth: '500px', borderRadius: '10px' }} />
                </div>
            </div>

            <section className="consultations-with-images mt-5 mb-5">
                <h2 style={{ color: '#2c94d5', textAlign: 'center' }}>{t('microchipping.microchippingServices')}</h2>
                <div className="consultations-container d-flex justify-content-between align-items-center">
                    <div className="portrait-image">
                        <img src={LeftImg} alt="Left image" className="consultation-img" />
                    </div>
                    <div className="consultations-portrait d-flex flex-column align-items-center">
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faMicrochip} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('microchipping.services.permanentIdentification')}</p>
                        </div>
                        
                        <div className="consultation-item">
                            <FontAwesomeIcon icon={faLock} size="3x" style={{ color: '#2c94d5' }} />
                            <p>{t('microchipping.services.enhancedSecurity')}</p>
                        </div>
                    </div>
                    <div className="portrait-image">
                        <img src={RightImg} alt="Right image" className="consultation-img" />
                    </div>
                </div>
            </section>

            <section className="benefits mt-5 mb-5">
                <h2 style={{ color: '#90c349' }}>{t('microchipping.benefits.header')}</h2>
                <div className="benefits-container d-flex justify-content-between">
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faMicrochip} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('microchipping.benefits.items.permanentIdentification')}</p>
                    </div>
                   
                    <div className="benefit-item text-center">
                        <FontAwesomeIcon icon={faLock} size="3x" style={{ color: '#90c349' }} />
                        <p>{t('microchipping.benefits.items.peaceOfMind')}</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Microchipping;
